import Link from "next/link"
import Layout from "../components/Layout"
import fetchNav from "../utils/fetch-nav"
import fetchCompany from "../utils/fetch-company"
import { createApolloClient } from "../utils/apollo"

const Error404 = ({ navs, company }) => {
  const seo = {
    title: "Fehler 404",
    description: "Seite nicht gefunden.",
  }

  return (
    <Layout navs={navs} company={company}>
      <div className="container">
        <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
          <main className="flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-sm font-semibold text-vollmagenta-700 uppercase tracking-wide">
                  404 error
                </p>
                <h1 className="mt-2 font-extrabold text-gray-900 tracking-tight h3">
                  Seite nicht gefunden 💥
                </h1>
                <p className="mt-2 text-gray-404">
                  Sorry, diese Seite existiert nicht. Unsere Startseite bietet
                  Orientierung.
                </p>
                <div className="mt-6">
                  <Link href="/">
                    <a className="button">
                      Hier geht&apos;s zur Startseite{" "}
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

// Data for current page
export const getStaticProps = async (context) => {
  const { previewData } = context
  const apollo = createApolloClient(previewData?.token ?? null)
  const [navs, company] = await Promise.all([
    fetchNav(apollo, previewData?.unit),
    fetchCompany(apollo),
  ])

  return {
    props: {
      navs,
      company,
    },
  }
}

export default Error404
